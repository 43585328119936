<template>
  <div class="row">
    <div class="col-md-12">
      <div class="panel">
        <div class="panel-heading bg-dark text-light">
          <h3 class="panel-title bold">List Pendukung</h3>
        </div>
        <div class="panel-body p-5">
          <div class="row mb-2 mt-2" v-if="userLevel == 'admin'">
            <div class="col-6 col-md-3">
              <select
                v-model="filter.kecamatan"
                v-on:change="fetchKelurahan($event)"
                class="form-control"
              >
                <option value="">Pilih</option>
                <option
                  v-for="kecamatan in listKecamatan"
                  :value="kecamatan.id_kec"
                  :key="kecamatan.id_kec"
                  :data-key="kecamatan.id_kec"
                >
                  {{ kecamatan.nama_kecamatan }}
                </option>
              </select>
            </div>
            <div class="col-6 col-md-3">
              <select
                v-model="filter.kelurahan"
                class="form-control"
                v-on:change="fetchData()"
              >
                <option value="">Pilih</option>
                <option
                  v-for="kelurahan in listKelurahan"
                  :value="kelurahan.id_kel"
                  :key="kelurahan.id_kel"
                >
                  {{ kelurahan.nama_kelurahan }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-2 mt-2" v-if="userLevel == 'korcam'">
            <div class="col-6">
              <select
                @change="fetchData"
                v-model="filter.kelurahan"
                class="form-control"
              >
                <option value="">Pilih Desa</option>
                <option
                  v-for="kelurahan in listDesa"
                  :value="kelurahan.nama_kelurahan"
                  :key="kelurahan.nama_kelurahan"
                >
                  {{ kelurahan.nama_kelurahan }}
                </option>
              </select>
            </div>
          </div>
          <vue-good-table
            mode="remote"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
            @on-search="onSearch"
            :totalRows="totalRecords"
            :isLoading.sync="isLoading"
            :search-options="{
              enabled: true,
              trigger: 'keyup',
              placeholder: 'Pencarian',
            }"
            :pagination-options="{
              enabled: true,
              perPageDropdownEnabled: true,
              perPageDropdown: [10, 20, 30, 40, 50, 100],
              dropdownAllowAll: false,
              position: 'bottom',
              rowsPerPageLabel: '',
              nextLabel: '',
              prevLabel: '',
              ofLabel: 'of',
              pageLabel: 'page',
              allLabel: '',
            }"
            :rows="rows"
            :columns="columns"
          >
            <div slot="emptystate" class="text-center">
              {{ tableInfo }}
            </div>
            <div slot="table-actions">
              <div style="display: flex">
                <button
                  v-if="filterReset"
                  type="button"
                  v-on:click="resetFilter()"
                  class="btn btn-danger btn-outline-danger mr-2"
                >
                  <i class="fa fa-times"></i>
                </button>
                <button
                  type="button"
                  v-on:click="fetchData()"
                  class="btn btn-white mr-2"
                >
                  <i v-if="isLoading" class="fa fa-sync fa-spin"></i>
                  <i v-else class="fa fa-sync"></i>
                </button>
                <router-link
                  :to="'/quick-count/create/'"
                  class="btn btn-success m-l-5 m-r-5"
                >
                  <i class="fa fa-plus d-md-none"></i>
                  <span class="d-none d-md-block">Tambah Data</span>
                </router-link>
              </div>
            </div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'action'">
                <div>
                  <router-link
                    :to="'quick-count/update/' + props.row.id_quick_qount"
                    class="btn btn-circle btn-icon btn-warning m-r-5"
                    v-if="
                      (userID == props.row.created_by &&
                        userLevel == 'kordes') ||
                      userLevel == 'admin' ||
                      userLevel == 'korcam'
                    "
                  >
                    <i class="fa fa-pencil-alt"></i>
                  </router-link>
                  <button
                    v-if="
                      (userID == props.row.created_by &&
                        userLevel == 'kordes') ||
                      userLevel == 'admin' ||
                      userLevel == 'korcam'
                    "
                    class="btn btn-circle btn-icon btn-danger"
                    v-on:click="confirmDelete(props.row.id_quick_qount)"
                  >
                    <i class="fa fa-trash-alt"></i>
                  </button>
                </div>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import sipData from "../../plugins/sipData";
import moment from "moment";

export default {
  mixins: [sipData],
  data() {
    return {
      columns: [
        {
          label: "Kecamatan",
          field: "nama_kec",
          sortable: false,
          thClass: "text-center align-middle text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Desa",
          field: "nama_kel",
          sortable: false,
          thClass: "text-center align-middle text-wrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "TPS",
          field: "tps",
          sortable: false,
          thClass: "text-center align-middle text-wrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "DPT",
          field: "dpt",
          sortable: false,
          thClass: "text-center align-middle text-wrap",
          tdClass: "text-center text-nowrap text-uppercase",
        },
        {
          label: "Sutrianto",
          field: "caleg01",
          sortable: false,
          thClass: "text-center align-middle text-wrap",
          tdClass: "text-center text-nowrap text-uppercase",
        },
        {
          label: "I.J.L",
          field: "caleg02",
          sortable: false,
          thClass: "text-center align-middle text-wrap",
          tdClass: "text-center  text-uppercase text-nowrap",
        },
        {
          label: "Fatma",
          field: "caleg04",
          sortable: false,
          thClass: "text-center align-middle text-wrap",
          tdClass: "text-center text-uppercase text-nowrap",
        },
        {
          label: "M.A.N",
          field: "caleg03",
          sortable: false,
          thClass: "text-center align-middle text-wrap",
          tdClass: "text-center text-uppercase text-nowrap",
        },
        {
          label: "Safriyanti",
          field: "caleg05",
          sortable: false,
          thClass: "text-center align-middle text-wrap",
          tdClass: "text-center text-uppercase text-nowrap",
        },
        {
          label: "Andi Muchtar",
          field: "caleg06",
          sortable: false,
          thClass: "text-center align-middle text-wrap",
          tdClass: "text-center text-uppercase text-nowrap",
        },
        {
          label: "Aksi",
          field: "action",
          sortable: false,
          width: "100px",
          tdClass:
            "text-center f-s-14 f-w-600 text-inverse valign-middle text-nowrap",
          thClass: "text-center align-middle text-nowrap",
        },
      ],
      rows: null,
      tableInfo: "Loading data",
      isLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: "",
            type: "",
          },
        ],
        page: 1,
        perPage: 10,
      },
      form: {
        nama: "",
        kecamatan: "",
        kelurahan: "",
      },
      filter: {
        kecamatan: "",
        kelurahan: "",
      },
      searchTerm: "",
      timeoutSearch: null,
      modalVisible: false,
      conditionMet: false,
      formValidate: [],
      listKecamatan: [],
      listKelurahan: [],
      listDesa: [],
      listDPT: [],
      statusDPT: "",
      loadDPT: false,
      userLevel: "",
      filterReset: false,
    };
  },
  created() {
    moment.updateLocale("en", {
      months: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
    });
  },
  mounted() {
    const filterData = JSON.parse(localStorage.getItem("filterData"));
    if (filterData) {
      this.filterReset = true;
      this.searchTerm = filterData.search;
      this.serverParams.page = filterData.page;
      this.serverParams.perPage = filterData.limit;
      this.filter.kecamatan = filterData.kecamatan;
      this.filter.kelurahan = filterData.kelurahan;
    }
    this.fetchData();
    this.fetchKecamatan();
    this.fetchDesa();
  },
  methods: {
    onSearch(params) {
      clearTimeout(this.timeoutSearch);
      this.timeoutSearch = setTimeout(() => {
        this.searchTerm = params.searchTerm;
        this.fetchData();
      }, 500);
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.fetchData();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.fetchData();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params.sortType,
            field: this.columns[params.columnIndex].field,
          },
        ],
      });
      this.fetchData();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.fetchData();
    },

    fetchData() {
      this.isLoading = true;
      this.setFiltered();
      axios
        .get("/v1/quickcount/", {
          headers: {
            token: this.userToken,
          },
          params: {
            search: this.searchTerm,
            page: this.serverParams.page,
            limit: this.serverParams.perPage,
            kecamatan: this.filter.kecamatan,
            kelurahan: this.filter.kelurahan,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          this.totalRecords = response.data.total;
          if (this.totalRecords <= 0) {
            this.tableInfo = "Empty data";
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },

    fetchKecamatan() {
      axios
        .get("/v1/daerah/kecamatan/7209", {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.listKecamatan = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },

    fetchKelurahan(event) {
      const selectedOption = event.target.options[event.target.selectedIndex];
      const key = selectedOption.getAttribute("data-key");

      console.log(key);
      axios
        .get("/v1/daerah/kelurahan/" + key, {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.listKelurahan = response.data.data;
          this.fetchData();
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },

    fetchDesa() {
      axios
        .get("/v1/daerah/desa/", {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.listDesa = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },

    fetchDPT() {
      this.loadDPT = true;

      const formData = new FormData();
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }

      axios
        .post("/v1/dpt/cek", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loadDPT = false;
            this.formValidate = response.data.message;
          } else {
            if (response.data.status == true) {
              this.listDPT = response.data.data;
              this.statusDPT = "1";
            } else {
              this.statusDPT = "0";
            }
            this.loadDPT = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },

    confirmDelete(id) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: "Data Akan Terhapus Secara Permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus!",
      }).then((result) => {
        if (result.value) {
          this.deleteData(id);
        }
      });
    },
    deleteData(id) {
      try {
        axios
          .get("/v1/quickcount/delete/" + id)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: "Sukses",
                text: "Data Berhasil Dihapus",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK!",
              }).then(() => {
                this.fetchData();
              });
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },
    formatDateTime(dateTime) {
      return moment(dateTime).format("D MMMM YYYY");
    },
    showModal() {
      this.modalVisible = true;
      this.conditionMet = false;
    },
    closeModal() {
      this.modalVisible = false;
      this.conditionMet = true;
    },
    beforeModalClose(evt) {
      if (!this.conditionMet) {
        evt.preventDefault();
      }
    },
    manualDPT() {
      const dptManual = {
        kecamatan: this.form.kecamatan,
        kelurahan: this.form.kelurahan,
        nama: this.form.nama,
      };

      localStorage.setItem("dptManual", JSON.stringify(dptManual));
      this.$router.push("data-pendukung/manual/");
    },
    setPendukung(params) {
      this.$router.push("data-pendukung/dpt/" + params);
      // window.alert(params);
    },
    setSaksi(params) {
      this.$router.push("data-saksi/set/" + params);
      // window.alert(params);
    },
    setRelawan(params) {
      this.$router.push("data-relawan/set/" + params);
      // window.alert(params);
    },
    setFiltered() {
      var filterData = JSON.parse(localStorage.getItem("filterData"));
      filterData = {
        search: this.searchTerm,
        page: this.serverParams.page,
        limit: this.serverParams.perPage,
        kecamatan: this.filter.kecamatan,
        kelurahan: this.filter.kelurahan,
      };
      localStorage.setItem("filterData", JSON.stringify(filterData));
      if (
        this.searchTerm ||
        this.serverParams.page > 1 ||
        this.serverParams.perPage != 10 ||
        this.filter.kecamatan ||
        this.filter.kelurahan
      ) {
        this.filterReset = true;
      } else {
        this.filterReset = false;
      }
    },
    resetFilter() {
      localStorage.removeItem("filterData");
      this.filterReset = false;
      this.searchTerm = "";
      this.serverParams.page = 1;
      this.serverParams.perPage = 10;
      this.filter.kecamatan = "";
      this.filter.kelurahan = "";
      this.fetchData();
    },
  },
};
</script>